html,
body {
   margin:0;
   padding:0;
   height:100%;
}
#container {
    min-height:100%;
    position:relative;
 }
#footer {
    position:fixed;
    bottom:0;
    width:100%;
    min-height:60px;   /* Height of the footer */ 
    background-color: #000;
    padding:0 25px;
    max-height: 100px;
 }
 #footer button{
    color:#fff;
 }
 .footer-carousel img{
   width:60px;
   height: 60px;
   margin-top:10px;
   cursor: pointer;
 }
 .footer-carousel label {
   color:#fff;
   padding-top:5px;
 }
 .slick-slide {
   width: 80px;
}
.slick-next {
   right: 25px;
}